const colors = {
  offWhite: `#D9D9D9`,
  offWhite400: "#FFFFFF",
  offWhite300: `#f2f1f1`,
  drePurple: "#825EEA",
  dreOrange400: "#FF8600",
  dreOrange300: "#FFA440",
  almostBlack: "#3D3D3D",
};

export default colors;
