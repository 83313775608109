import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CookieConsent, { Cookies } from "react-cookie-consent";
import colors from "styles/colors";
import text from "styles/text";
import { Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { ReactComponent as DreArrowSVG } from "assets/svg/dreArrow.svg";
import media from "styles/media";

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(false);
  const [myLocation, setMyyLocation] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMyyLocation(window.location);
    }
  }, []);

  useEffect(() => {
    let newAccepted = Cookies.get("gatsby-gdpr-google-analytics");
    setAccepted(newAccepted);
  }, []);

  return (
    <Wrapper accepted={accepted}>
      <CookieConsent
        cookieName="gatsby-gdpr-google-analytics"
        buttonText="Accept"
        onAccept={() => {
          Cookies.set("gatsby-gdpr-google-tagmanager", "true");
          initializeAndTrack(myLocation);
          setAccepted(true);
        }}
        containerClasses="container"
        buttonClasses="button"
        disableStyles={true}>
        <Overline>COOKIES</Overline>
        <Text>By continuing to browse this website, you agree to use analytical cookies to collect website visit statistics.</Text>

        <Link className="privacy" to="/privacy">
          Learn More
          <DreArrow />
        </Link>
      </CookieConsent>
    </Wrapper>
  );
};

export default CookieBanner;
const DreArrow = styled(DreArrowSVG)`
  width: 0.89vw;
  height: 0.95vw;
  margin-left: 0.6vw;
  transition: 0.4s;
  path {
    stroke: ${colors.offWhite300};
  }
  ${media.tablet} {
    width: 1.47vw;
    height: 1.56vw;
    margin-left: 0.98vw;
  }
  ${media.mobile} {
    width: 4vw;
    height: 4.27vw;
    margin-left: 2.67vw;
  }
`;

const Wrapper = styled.div<{ accepted: boolean }>`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 3vw;
  width: 21.25vw;
  height: 15.95vw;
  left: auto;
  background: black;
  right: 5.36vw;
  box-sizing: border-box;
  z-index: ${(props) => (props.accepted ? -1 : 50)};

  ${media.tablet} {
    width: 62.79vw;
    height: 22.37vw;
    left: 9.08vw;
    top: 95.94vw;
    border-radius: 0.98vw;
    justify-content: flex-start;
  }
  ${media.tabletWide} {
    left: auto;
    right: 8vw;
    top: 45.94vw;
  }

  ${media.mobile} {
    width: 92vw;
    height: 71.47vw;
    left: 4vw;
    top: 100.87vw;
    border-radius: 2.67vw;
    justify-content: flex-start;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    background: black;
    overflow: hidden;
    font-family: "Suisse Int'l";
    color: ${colors.offWhite300};
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 25%);
    padding: 1.79vw 1.25vw 2.5vw 1.79vw;
    position: relative;
    ${media.tablet} {
      padding: 2.05vw 0 3.52vw 2.93vw;
    }
    ${media.mobile} {
      padding: 8vw 0 11.2vw 6.67vw;
    }
  }
  .button {
    color: ${colors.offWhite300};
    background-color: black;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border: 1px solid ${colors.offWhite300};
    border-radius: 5.95vw;
    padding: 0.89vw 2.5vw;
    font-size: 1.19vw;
    position: absolute;
    left: 1.79vw;
    top: 10.48vw;
    transition: 0.4s;
    ${media.hover} {
      :hover {
        background: ${colors.drePurple};
      }
    }
    ${media.tablet} {
      position: absolute;
      top: auto;
      bottom: 3.52vw;
      left: 2.93vw;
      font-size: 1.96vw;
      padding: 1.47vw 4.1vw;
      border-radius: 9.77vw;
    }
    ${media.mobile} {
      font-size: 5.33vw;
      padding: 4vw 11.2vw;
      border-radius: 26.67vw;
      top: 46.93vw;
      left: 6.67vw;
    }
  }
  .privacy {
    position: absolute;
    top: 11.37vw;
    left: 11.9vw;
    display: flex;
    align-items: center;
    color: ${colors.offWhite300};
    text-decoration: none;
    ${media.hover} {
      :hover {
        ${DreArrow} {
          transform: translateX(5px);
        }
      }
    }
    ${media.tablet} {
      left: 19.43vw;
      top: 14.66vw;
      font-size: 1.96vw;
    }
    ${media.mobile} {
      left: 50.93vw;
      top: 50.67vw;
      font-size: 5.33vw;
    }
  }
`;

const Text = styled.p`
  ${text.fullWidth.Body}

  text-align: left;
  width: 100%;
  margin-bottom: 1.79vw;
  ${media.tablet} {
    ${text.tablet.Body}
    width: 51.17vw;
    margin-bottom: 2.93vw;
  }
  ${media.mobile} {
    ${text.mobile.Body}
    width: 82.67vw;
  }
`;

const Overline = styled.h6`
  ${text.desktop.Link};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 1.19vw;
  color: ${colors.drePurple};
  ${media.tablet} {
    font-size: 1.76vw;
  }
  ${media.mobile} {
    font-size: 4.8vw;
    margin-bottom: 5.33vw;
  }
`;
