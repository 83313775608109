import React, { createContext, useState, useEffect } from "react";
import styled from "styled-components";
import media, { desktop, tablet, mobile } from "styles/media";
import colors from "styles/colors";
import SEO from "components/SEO";
import Header from "components/Header";
import Footer from "components/Footer";
import CookieBanner from "./cookie-banner";

export const MobileContext = createContext(false);
export const TabletContext = createContext(false);
export const TabletPortraitContext = createContext(false);
export const DesktopContext = createContext(false);
export const FullWidthContext = createContext(false);

interface Props {
  children: any;
  title: string;
}

const Layout = ({ children, title }: Props): JSX.Element => {
  const [mobileBool, setMobile] = useState<boolean>(false);
  const [tabletBool, setTablet] = useState<boolean>(false);
  const [tabletPortraitBool, setTabletPortrait] = useState<boolean>(false);
  const [desktopBool, setDesktop] = useState<boolean>(false);
  const [fullWidthBool, setFullWidth] = useState<boolean>(false);
  const isBrowser = () => typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser()) {
      window.scrollTo(0, 0);
      setMobile(window.innerWidth <= mobile);
      setTablet(window.innerWidth > mobile && window.innerWidth <= tablet);
      setTabletPortrait(tablet && window.innerWidth < window.innerHeight);
      setDesktop(window.innerWidth > tablet && window.innerWidth <= desktop);
      setFullWidth(window.innerWidth > desktop);

      window.addEventListener("resize", () => {
        setMobile(window.innerWidth <= mobile);
        setTablet(window.innerWidth > mobile && window.innerWidth <= tablet);
        setTabletPortrait(tablet && window.innerWidth < window.innerHeight);
        setDesktop(window.innerWidth > tablet && window.innerWidth <= desktop);
        setFullWidth(window.innerWidth > desktop);
      });

      return () => window.removeEventListener("resize", () => false);
    }
  }, []);

  return (
    <FullWidthContext.Provider value={fullWidthBool}>
      <DesktopContext.Provider value={desktopBool}>
        <TabletContext.Provider value={tabletBool}>
          <TabletPortraitContext.Provider value={tabletPortraitBool}>
            <MobileContext.Provider value={mobileBool}>
              <SEO title={title} />
              <CookieBanner />
              <Header />
              <Widget id="eventbrite-widget-container-348629609547" />
              <Main>{children}</Main>
              <Footer />
            </MobileContext.Provider>
          </TabletPortraitContext.Provider>
        </TabletContext.Provider>
      </DesktopContext.Provider>
    </FullWidthContext.Provider>
  );
};

const Main = styled.main`
  width: 100%;
  background: ${colors.offWhite};
  ${media.mobile} {
    overflow: hidden;
  }
`;

const Widget = styled.div`
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

export default Layout;
