import colors from "styles/colors";
import media from "styles/media";

const text = {
  fullWidth: {
    Ticker: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 700;
      font-size: 5.71vw;
      line-height: 100%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      `,
    H1: `font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 600;
      font-size: 5.36vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    H2: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 600;
      font-size: 4.46vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    H3: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 600;
      font-size: 3.57vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    H4: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 2.14vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    H5: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 1.43vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    H6: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 1.31vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,

    SpeakerName: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 2.86vw;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    Link: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    Overline: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 1.07vw;
      line-height: 100%;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      `,
    OverlineS: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 0.83vw;
      line-height: 100%;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      `,
    BtnText: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.02em;
      `,
    Body: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: 400;
      font-size: 1.01vw;
      line-height: 170%;
      `,
    BodyS: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 0.71vw;
      line-height: 170%;
      `,
    BodyXS: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 0.83vw;
      line-height: 110%;
      `,
    BodyXXS: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 0.6vw;
      line-height: 110%;
      letter-spacing: -0.02em;
      `,
    Nav12: `
      font-family: "Suisse Int'l";
      font-style: normal;
      font-weight: normal;
      font-size: 0.71vw;
      line-height: 100%;
      letter-spacing: -0.02em;
    `,
  },
  desktop: {
    BtnText: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 300;
    font-size: 1.19vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    Link: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 300;
    font-size: 1.07vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
  },
  tablet: {
    Ticker: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 7.79vw;
    line-height: 100%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    `,
    H1: `font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 400;
    font-size: 7.79vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    H2: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 600;
    font-size: 4.46vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    H3: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 500;
    font-size: 5.76vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    H4: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 2.14vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    H5: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 2.64vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    H6: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 2.4vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,

    SpeakerName: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 600;
    font-size: 3.84vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    Link: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 300;
    font-size: 1.07vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    Overline: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 2.16vw;
    line-height: 100%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    `,
    OverlineS: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 1.68vw;
    line-height: 100%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    `,
    BtnText: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 300;
    font-size: 2.4vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    Body: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 1.92vw;
    line-height: 170%;
    `,
    BodyS: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 1.92vw;
    line-height: 170%;
    `,
    BodyXS: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 0.83vw;
    line-height: 110%;
    `,
    BodyXXS: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 1.44vw;
    line-height: 110%;
    letter-spacing: -0.02em;
    `,
    Nav12: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 1.17vw;
    line-height: 100%;
    letter-spacing: -0.02em;
  `,
  },
  mobile: {
    Ticker: `
      font: 700 10.7vw "Suisse Int'l";
      line-height: 100%;
      text-transform: uppercase;
    `,
    H1: `
      font: 600 10.7vw "Suisse Int'l";
      letter-spacing: -0.02em;
    `,
    H3: `
      font: 600 8.5vw "Suisse Int'l";
      letter-spacing: -0.02em;
    `,
    H5: `
      font: normal 5.9vw "Suisse Int'l";
      line-height: 100%;
      letter-spacing: -0.02em;
    `,
    H6: `
      font: normal 5.33vw "Suisse Int'l";
      line-height: 100%;
      letter-spacing: -0.02em;
    `,
    Overline: `
      font: normal 4.3vw "Suisse Int'l";
      line-height: 100%;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    `,
    OverlineS: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 3.73vw;
    line-height: 100%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    `,
    BtnText: `
      font: 300 5.3vw "Suisse Int'l";
      line-height: 100%;
      letter-spacing: -0.02em;
    `,
    Link: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 400;
    font-size: 4.53vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    SpeakerName: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: 600;
    font-size: 8.53vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    `,
    Body: `
      font: normal 4.3vw "Suisse Int'l";
      line-height: 170%;
    `,
    BodyS: `
      font: normal 3.2vw "Suisse Int'l";
      line-height: 170%;
    `,
    Nav12: `
    font-family: "Suisse Int'l";
    font-style: normal;
    font-weight: normal;
    font-size: 2.8vw;
    line-height: 100%;
    letter-spacing: -0.02em;
  `,
  },
};

export const buttonStyle = `
appearance: none;
-webkit-appearance: none;
${text.desktop.BtnText};
border-radius: 5.95vw;
padding: 0.89vw 2.5vw;
text-decoration: none;
outline: none;
border: none;
background: ${colors.dreOrange400};
transition: 0.4s;
cursor: pointer;
color: black;
${media.hover} {
   :hover {
       background: ${colors.dreOrange300};
       transition: 0.4s;
   }
}
${media.fullWidth} {
  border-radius: 100px;
  padding: 15px 42px;
  ${text.fullWidth.BtnText};
}
${media.tablet} {
  padding: 1.8vw 4.32vw;
  ${text.tablet.BtnText};
}
${media.mobile} {
  ${text.mobile.BtnText}
  border-radius: 9vw;
  padding: 4vw 2vw;
  display: block;
  width: 100%;
  text-align: center;
}
`;

export const buttonAlt = `
${buttonStyle};
border: 1px solid black;
background: none;
${media.hover} {
    :hover {
        background: ${colors.offWhite300};
        transition: 0.4s;
    }
 }
`;

export default text;
