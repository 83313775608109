import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import metaImage from "../assets/images/MetaImage_2023.png";
import mobileMetaImage from "../assets/images/MetaImage_square_2023.png";
import { MobileContext } from "./layout";

interface Props {
  description?: string;
  lang?: string;
  meta?: Meta;
  title?: string;
  image?: string;
  url?: string;
}

const SEO = ({
  description,
  lang,
  meta,
  title,
  image,
  url,
}: Props): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  const mobile = useContext(MobileContext);
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: "og:image",
          content: `https://drecon.org${mobile ? mobileMetaImage : metaImage}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: `https://drecon.org${mobile ? mobileMetaImage : metaImage}`,
        },
        {
          name: `twitter:creator`,
          content: ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1, minimal-ui",
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: "",
  image: "",
};

type PropertyMetaObj = {
  property: string;
  content: string;
};

type NameMetaObj = {
  name: string;
  content: string;
};

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>;

export default SEO;
