import paths from "constants/paths";
import { navigate } from "gatsby-link";
import gsap from "gsap";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";
import media from "styles/media";
import text, { buttonStyle } from "styles/text";
import { ReactComponent as BigeyeSVG } from "svg/bigeye.svg";
import { ReactComponent as DREArrowSVG } from "svg/dreArrow.svg";
import { ReactComponent as LogoSVG } from "svg/dreLogo.svg";
import linkedin from "svg/linkedInBlack.svg";
import medium from "svg/mediumBlack.svg";
import slack from "svg/slackBlack.svg";
import twitter from "svg/twitterBlack.svg";

const Footer = (): JSX.Element => {
  const handleClick = (scrollID: string, active: number) => {
    const homepage = window.location.pathname === "/";

    if (homepage) {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: { y: scrollID, offsetY: 80 },
      });
    } else {
      navigate(active < 3 ? "/" : scrollID, {
        state: { scrollID },
      });
    }
  };

  const icons = [
    { icon: linkedin, link: "https://www.linkedin.com/company/bigeye-data/" },
    { icon: twitter, link: "https://twitter.com/bigeyedata" },
    { icon: medium, link: "https://medium.com/bigeye" },
    {
      icon: slack,
      link: "https://join.slack.com/t/datareliabili-h4y1326/shared_invite/zt-16md9v2dw-DjHdb_zSg7UD6i5iayYqyg",
    },
  ];

  const allIcons = icons.map((icon, i) => {
    if (i !== 3) {
      return (
        <a
          key={`icon-${i}`}
          href={icon.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={icon.icon} big={false} rightMost={i === 2} />
        </a>
      );
    } else {
      return <Icon src={icon.icon} big={true} rightMost={false} />;
    }
  }, []);

  return (
    <Wrapper>
      <LogoSection>
        <LinkHome href="/">
          <Logo />
        </LinkHome>
        <Line />
        <Bigeye>
          <p>Presented&nbsp;By</p>
          <a
            href="https://www.bigeye.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BigeyeLogo />
          </a>
        </Bigeye>
      </LogoSection>
      <Socials>{allIcons.slice(0, 3)}</Socials>
      <Nav>
        <PageLink onClick={() => handleClick("#overview", 0)}>
          Overview
        </PageLink>
        {/* <PageLink onClick={() => handleClick("#speakers", 1)}>
          Speakers
        </PageLink> */}
        <PageLink
          rel="noopener noreferrer"
          href={paths.PAST_EVENT_2021}
          target="_blank"
        >
          On Demand
        </PageLink>
        <PageLink href="/codeofconduct">Code of Conduct</PageLink>
        <PageLink href="/privacy">Privacy Policy</PageLink>
      </Nav>
      <Join href="https://join.slack.com/t/datareliabili-h4y1326/shared_invite/zt-16md9v2dw-DjHdb_zSg7UD6i5iayYqyg">
        {allIcons[3]}
        <span>Join the DRECon Slack Community </span> <DREArrow />
      </Join>
      <CallToAction>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://formulatedby.typeform.com/to/X3dqGbqK"
        >
          <ApplyToSpeak>Apply&nbsp;To&nbsp;Speak</ApplyToSpeak>
        </a> */}
        <RegisterNow href="#register" className="button">
          Register&nbsp;Now
        </RegisterNow>
      </CallToAction>
      <CopyRight>
        ©&nbsp;2021&nbsp;Bigeye.&nbsp;All&nbsp;rights&nbsp;Reserved.
      </CopyRight>

      <HRTop />
      <HRBottom />
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  padding: 2.98vw 5.95vw 4.35vw;
  width: 100%;
  position: relative;
  background: ${colors.offWhite};
  z-index: 10;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 0px #000000;
  padding-bottom: 2.083vw;

  display: grid;
  grid-template-columns: auto auto auto auto min-content;
  grid-template-areas:
    "logo nav  callToAction callToAction callToAction"
    "join join join         socials      copyright";
  align-items: center;
  grid-row-gap: 10.625vw;

  ${media.tablet} {
    height: auto;
    padding: 8.99vw 11.99vw 3.597vw;

    grid-row-gap: 7.194vw;
    grid-column-gap: 10.552vw;
    grid-template-columns: auto min-content auto;
    grid-template-areas:
      "logo      callToAction  callToAction"
      "nav       callToAction  callToAction"
      "hrtop     hrtop         hrtop"
      "join      join          socials"
      "hrbottom  hrbottom      hrbottom"
      "copyright copyright     copyright";
  }

  ${media.mobile} {
    height: auto;
    padding: 16vw 4vw 13.333vw;
    grid-row-gap: 10.667vw;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "socials"
      "nav"
      "hrtop"
      "join"
      "hrbottom"
      "callToAction"
      "copyright";
    place-items: center;
  }

  ${media.fullWidth} {
    padding: 50.06px 99.96px 73.08px;
    grid-row-gap: 153px;
  }
`;

// main sections

const LogoSection = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 20px; /* static */
  gap: 20px;

  ${media.mobile} {
    margin-top: -10px; /* compensate fore grid gap */
  }
`;

const Socials = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-top: 0.278vw; /* compensate for icons */
  margin-right: 1.736vw;

  grid-area: socials;
  justify-self: end;

  ${media.tablet} {
  }

  ${media.mobile} {
    justify-self: center;
  }

  ${media.fullWidth} {
    padding-top: 4px;
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3.125vw;
  grid-area: nav;
  justify-self: end;

  ${media.tablet} {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    margin: 0;
    width: 100%;
  }

  ${media.mobile} {
    flex-direction: column;
    justify-self: center;
    margin: 5.333vw auto;
  }
`;

const HRTop = styled.div`
  grid-area: hrtop;
  width: 100%;
  border-bottom: 1px solid black;
  ${media.desktop} {
    display: none;
  }
  ${media.fullWidth} {
    display: none;
  }
`;

const HRBottom = styled(HRTop)`
  grid-area: hrbottom;
`;

// dependency for "join" component below
const DREArrow = styled(DREArrowSVG)`
  width: 0.89vw;
  height: 0.95vw;
  margin-left: 0.6vw;
  transition: 0.4s;
  path {
    stroke: black;
  }
  ${media.tablet} {
    width: 1.8vw;
    height: 1.92vw;
    margin-left: 1.2vw;
  }
  ${media.mobile} {
    width: 4vw;
    height: auto;
    margin-left: 2.7vw;
  }
  ${media.fullWidth} {
  }
`;

const Join = styled.a`
  ${text.fullWidth.BodyS};
  align-items: center;
  display: flex;
  text-decoration: none;
  color: inherit;

  grid-area: join;

  ${media.hover} {
    :hover {
      ${DREArrow} {
        transform: translateX(0.36vw);
      }
    }
  }
  ${media.tablet} {
    ${text.tablet.BodyS};
  }
  ${media.mobile} {
    ${text.mobile.BodyS};
    font-size: 3.73vw;
    margin: -10px auto; /* compensate for grip gap */
  }
`;

const CallToAction = styled.div`
  grid-area: callToAction;
  display: flex;
  justify-content: flex-end;

  ${media.tablet} {
    align-self: start;
    flex-direction: column-reverse;
  }
  ${media.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;

const CopyRight = styled.p`
  ${text.fullWidth.BodyS};

  grid-area: copyright;
  justify-self: end;
  font-size: 12px;

  ${media.tablet} {
    ${text.tablet.BodyS};
    margin-top: -3.597vw; /* compensate for grid gap */
    font-size: 1.44vw;
  }
  ${media.mobile} {
    ${text.mobile.BodyS};
    justify-self: center;
  }
`;

// more specific components

const Logo = styled(LogoSVG)`
  width: 6.97vw;
  height: 2.08vw;
  ${media.tablet} {
    width: 14.03vw;
    height: 4.2vw;
  }
  ${media.mobile} {
    width: 31.2vw;
    height: 9.33vw;
  }
`;

const Bigeye = styled.div`
  justify-self: center;

  ${text.fullWidth.OverlineS};
  p {
    font-size: 0.83vw;
    letter-spacing: -0.02em;
  }
  ${media.tablet} {
    p {
      font-size: 1.68vw;
    }
  }
  ${media.mobile} {
    ${text.mobile.OverlineS};
    font-size: 3.73vw;
    line-height: 100%;
    letter-spacing: -0.02em;
    p {
      font-size: 3.73vw;
    }
  }
`;

const BigeyeLogo = styled(BigeyeSVG)`
  width: 5.12vw;
  height: 1.43vw;
  margin-top: 0.48vw;

  ${media.tablet} {
    width: 10.31vw;
    height: 2.88vw;
    margin-top: 0.96vw;
  }
  ${media.mobile} {
    width: 22.93vw;
    height: 6.4vw;
    margin-top: 2.13vw;
  }
`;

const Line = styled.div`
  height: 3.27vw;
  width: 0.06vw;
  background: ${colors.drePurple};
  ${media.tablet} {
    height: 6.59vw;
    width: 0.12vw;
  }
  ${media.mobile} {
    width: 0.267vw;
    height: 14.667vw;
  }
`;

const PageLink = styled.a`
  ${text.desktop.Link};
  text-decoration: none;
  margin: 0 1.042vw;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 1.07vw;
  letter-spacing: -0.02em;
  :after {
    content: "";
    width: 100%;
    height: 1px;
    background: ${colors.drePurple};
    bottom: -0.42vw;
    position: absolute;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.4s;
  }

  ${media.hover} {
    :hover {
      :after {
        transform: scaleX(1);
      }
    }
  }

  ${media.tablet} {
    font-size: 2.04vw;
    margin-bottom: 3.6vw;
    white-space: nowrap;
  }
  ${media.mobile} {
    ${text.mobile.Link};
    margin-bottom: 8vw;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  ${media.fullWidth} {
    font-size: 18px;
    margin-right: 13px;
    padding: 0 10px;
  }
`;

const RegisterNow = styled.a`
  ${buttonStyle};

  ${media.tablet} {
    padding: 1.8vw 8.27vw;
    margin-bottom: 2.398vw;
  }
  ${media.mobile} {
    margin-top: 5.333vw;
  }
  ${media.fullWidth} {
  }
`;

// const ApplyToSpeak = styled.button`
//   ${buttonAlt};
//   margin-right: 1.389vw;
//   ${media.tablet} {
//     margin-right: 0;
//     font-size: 2.4vw;
//     white-space: nowrap;
//     padding: 1.8vw 8.27vw;
//   }
//   ${media.mobile} {
//   }
//   ${media.fullWidth} {
//     margin-right: 10px;
//   }
// `;

const LinkHome = styled.a`
  text-decoration: none;
  color: black;
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Icon = styled.img<{ big: boolean; rightMost: boolean }>`
  width: ${(props) => (props.big ? "2.62vw" : "1.458vw")};
  height: ${(props) => (props.big ? "2.62vw" : "1.458vw")};
  margin-right: ${(props) => (props.big ? "0.89vw" : "1.042vw")};

  ${media.tablet} {
    width: ${(props) => (props.big ? "5.04vw" : "2.518vw")};
    height: ${(props) => (props.big ? "5.04vw" : "2.518vw")};

    margin-right: ${(props) => (props.rightMost ? 0 : "1.56vw")};
  }
  ${media.mobile} {
    width: ${(props) => (props.big ? "11.2vw" : "8vw")};
    height: ${(props) => (props.big ? "11.2vw" : "8vw")};

    margin-right: ${(props) => (props.rightMost ? 0 : "3.74vw")};
  }

  ${media.fullWidth} {
    width: ${(props) => (props.big ? "44px" : "21px")};
    height: ${(props) => (props.big ? "44px" : "21px")};
    margin-right: ${(props) => (props.rightMost ? 0 : "15px")};
  }
`;

export default Footer;
