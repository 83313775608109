// import useScript from "hooks/useScript";
import paths from "constants/paths";
import React from "react";
import styled from "styled-components";
// import media from "styles/media";
import { buttonStyle } from "styles/text";

// const triggerId = "bizzabo-register";

const RegisterNowButton = () => {
  // useScript({
  //   url: "https://organizer.bizzabo.com/widgets/flows/popup/registrationPopup.js",
  //   attributes: {
  //     "data-flow-id": "f4a3df9d-c5d2-44da-b03d-5dfc2d8d53eb",
  //     "data-inline-widget": "true",
  //   },
  // });

  return (
    <>
      <Link href={paths.PAST_EVENT_2023} rel="noopener">
        On Demand
      </Link>

      {/* <Button id={triggerId}>Register Now</Button> */}

      {/* <script
        type="text/javascript"
        id="bz-popup-registration-script-f4a3df9d-c5d2-44da-b03d-5dfc2d8d53eb"
        data-event-id="434625"
        data-registration-proxy="true"
        data-unique-name="434625"
        data-flow-id="f4a3df9d-c5d2-44da-b03d-5dfc2d8d53eb"
        data-inline-widget="true"
        data-element-id={triggerId}
        data-element-class=""
      /> */}
    </>
  );
};

// const Button = styled.button`
//   ${buttonStyle};
//   ${media.tablet} {
//   }
//   ${media.mobile} {
//   }
//   ${media.fullWidth} {
//   }
// `;

const Link = styled.a`
  ${buttonStyle}
`;

export default RegisterNowButton;
