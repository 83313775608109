import React, { useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
import { ReactComponent as DREArrowSVG } from "svg/dreArrow.svg";
import { ReactComponent as SlackSVG } from "svg/slackBlack.svg";
import { ReactComponent as XButtonSVG } from "svg/xButton.svg";

const Banner = (): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) {
    return null;
  }

  return (
    <Wrapper>
      <Inner>
        <Join
          href="https://join.slack.com/t/datareliabili-h4y1326/shared_invite/zt-16md9v2dw-DjHdb_zSg7UD6i5iayYqyg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon /> <span>Join the DRECon Slack Community </span> <DREArrow />
        </Join>
        <XButton aria-label="close banner" onClick={() => setIsOpen(false)}>
          <XButtonX />
        </XButton>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 3.57vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.drePurple};
  z-index: 110;
  padding: 0 5.95vw;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  overflow: hidden;
  ${media.tablet} {
    height: 7.19vw;
    padding: 0;
  }

  ${media.mobile} {
    height: 16vw;
    padding: 0;
  }
`;

const Icon = styled(SlackSVG)`
  width: 2.62vw;
  height: 2.62vw;
  margin-right: 0.89vw;
  color: ${colors.offWhite400};
  path {
    fill: currentColor;
  }
  ${media.tablet} {
    width: 3.36vw;
    height: 3.36vw;
    margin-right: 1.56vw;
  }
  ${media.mobile} {
    width: 28px;
    height: 28px;
    margin-right: 3.74vw;
  }

  ${media.fullWidth} {
    width: 44px;
    height: 44px;
  }
`;

const DREArrow = styled(DREArrowSVG)`
  width: 0.89vw;
  height: 0.95vw;
  margin-left: 0.6vw;
  transition: 0.4s;
  path {
    stroke: ${colors.offWhite400};
  }
  ${media.tablet} {
    width: 1.8vw;
    height: 1.92vw;
    margin-left: 1.2vw;
  }
  ${media.mobile} {
    width: 4vw;
    height: auto;
    margin-left: 2.7vw;
  }
  ${media.fullWidth} {
  }
`;

const Join = styled.a`
  ${text.fullWidth.BodyS};
  align-items: center;
  display: flex;
  text-decoration: none;
  color: ${colors.offWhite400};
  ${media.hover} {
    :hover {
      ${DREArrow} {
        transform: translateX(0.36vw);
      }
    }
  }
  ${media.tablet} {
    ${text.tablet.BodyS};
  }
  ${media.mobile} {
    ${text.mobile.BodyS};
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.57vw;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet} {
    height: 7.19vw;
  }
  ${media.mobile} {
    height: 16vw;
    justify-content: space-between;
    align-items: center;
    padding: 0 4vw;
  }
`;

const XButton = styled.button`
  position: absolute;
  width: 1.43vw;
  height: 1.43vw;
  right: 1.07vw;
  top: 1.07vw;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;

  ${media.tablet} {
    width: 2.88vw;
    height: 2.88vw;
    right: 2.16vw;
    top: 2.16vw;
  }
  ${media.mobile} {
    position: relative;
    top: auto;
    right: auto;
    width: 6.4vw;
    height: 6.4vw;
  }
  ${media.fullWidth} {
  }
`;

const XButtonX = styled(XButtonSVG)`
  width: 1.43vw;
  height: 1.43vw;
  ${media.tablet} {
    width: 2.88vw;
    height: 2.88vw;
  }
  ${media.mobile} {
    width: 6.4vw;
    height: 6.4vw;
  }
  ${media.fullWidth} {
  }
`;

export default Banner;
