import { MobileContext, TabletContext } from "components/layout";
import paths from "constants/paths";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import gsap from "gsap";
import tabletBalls from "images/tabletMenuBall.png";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
import { ReactComponent as BigeyeSVG } from "svg/bigeye.svg";
import LogoSVG from "svg/dreLogo.svg";
import LogoSVGWhite from "svg/dreLogoWhite.svg";
import { ReactComponent as HammySVG } from "svg/hammy.svg";
import Banner from "./banner";
import RegisterNowButton from "./RegisterNowButton";

const Header = (): JSX.Element => {
  const header = useRef<HTMLElement>(null);
  const [navOpen, setNavOpen] = useState(false);
  const tablet = useContext(TabletContext);
  const mobile = useContext(MobileContext);
  const [activeGrad, setActiveGrad] = useState(-1);

  const handleClick = (scrollID: string, active: number) => {
    const homepage = window.location.pathname === "/";
    if (homepage && active < 3) {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: { y: scrollID, offsetY: 80 },
        onStart: () => setActiveGrad(active),
        onComplete: () => {
          (tablet || mobile) && handleOpen();
        },
      });
    } else {
      gsap.to(window, {
        duration: 0.5,
        onStart: () => setActiveGrad(active),
        onComplete: () => {
          navigate(active < 3 ? "/" : scrollID, {
            state: { scrollID },
          });
        },
      });
    }
  };

  const handleOpen = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    const tl = gsap.timeline({ onComplete: () => setActiveGrad(-1) });

    if (navOpen) {
      tl.to(".ham_1", { y: 12, transformOrigin: "50% 50%", duration: 0.2 }, 0)
        .to(".ham_3", { y: -12, transformOrigin: "50% 50%", duration: 0.2 }, 0)
        .to(
          ".ham_3",
          {
            rotate: -45,
            transformOrigin: "50% 50%",
            duration: 0.2,
          },
          0.2
        )
        .to(
          ".ham_1",
          { rotate: 45, transformOrigin: "50% 50%", duration: 0.2 },
          0.2
        )
        .to(".ham_2", { opacity: 0, duration: 0.2 }, 0);
    } else {
      tl.to(".ham_1", { y: 0, transformOrigin: "50% 50%", duration: 0.2 }, 0.2)
        .to(".ham_3", { y: 0, transformOrigin: "50% 50%", duration: 0.2 }, 0.2)
        .to(
          ".ham_3",
          {
            rotate: 0,
            transformOrigin: "50% 50%",
            duration: 0.2,
          },
          0
        )
        .to(
          ".ham_1",
          { rotate: 0, transformOrigin: "50% 50%", duration: 0.2 },
          0
        )
        .to(".ham_2", { opacity: 1, duration: 0.2 }, 0);
    }
  }, [navOpen]);

  return (
    <Wrapper>
      <Banner />
      <Inner ref={header} navOpen={navOpen}>
        <LinkHome navOpen={navOpen} href="/">
          <Logo src={navOpen ? LogoSVGWhite : LogoSVG} />
        </LinkHome>
        <Hamburger onClick={handleOpen} navOpen={navOpen}>
          <HammySVG />
        </Hamburger>
        <Nav linkOp={navOpen}>
          <PageLink
            activeGrad={activeGrad === 0}
            onClick={() => handleClick("#overview", 0)}
          >
            Overview
          </PageLink>
          <PageLink
            activeGrad={activeGrad === 1}
            onClick={() => handleClick("#speakers", 1)}
          >
            Speakers
          </PageLink>
          <PageLink
            activeGrad={activeGrad === 2}
            onClick={() => handleClick("#schedule", 2)}
          >
            Schedule
          </PageLink>

          {(tablet || mobile) && (
            <>
              <PageLink
                activeGrad={activeGrad === 4}
                onClick={() => handleClick("/codeofconduct", 4)}
              >
                Code of Conduct
              </PageLink>
              <PageLink
                activeGrad={activeGrad === 5}
                onClick={() => handleClick("/privacy", 5)}
              >
                Privacy Policy
              </PageLink>
            </>
          )}
          <PageLink2 to={paths.SEVEN_PRINCIPLES} activeGrad={activeGrad === 6}>
            7 Principles of DRE
          </PageLink2>
          {/* <PageLink2 to={paths.PAST_EVENT_2021} activeGrad={activeGrad === 3}>
            On Demand
          </PageLink2> */}
          <TabletDiv>
            {/*
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://formulatedby.typeform.com/to/X3dqGbqK"
            >
              <ApplyToSpeak>Apply To Speak</ApplyToSpeak>
            </a>
            */}
            {/* <Button onClick={() => handleClick("#schedule", 2)}>
              See the Schedule
            </Button> */}
            <RegisterNowButton />
          </TabletDiv>
          {(tablet || mobile) && (
            <>
              <Bigeye>
                <p>Presented By</p>
                <LinkHome href="https://www.bigeye.com">
                  <BigeyeLogo />
                </LinkHome>
                <CopyRight>
                  © {new Date().getFullYear()} Bigeye. All rights Reserved.
                </CopyRight>
              </Bigeye>
            </>
          )}
          {tablet && <MenuBalls src={tabletBalls} alt="two balls" />}
        </Nav>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 99;
`;

const Inner = styled.nav<{ navOpen: boolean }>`
  height: 4.47vw;
  width: 100%;
  position: relative;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.navOpen ? "black" : colors.offWhite)};
  z-index: 100;
  padding: 0 5.95vw;
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px #000000;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

  ${media.tablet} {
    color: ${(props) => (props.navOpen ? colors.offWhite : "black")};
    transition: 0.4s;
    height: ${(props) => (props.navOpen ? "100vh" : "8.99vw")};
    overflow: ${(props) => (props.navOpen ? "scroll" : "hidden")};
    flex-direction: column;
    padding: 0 0 0 11.99vw;
  }

  ${media.mobile} {
    color: ${(props) => (props.navOpen ? colors.offWhite : "black")};
    height: ${(props) => (props.navOpen ? "100vh" : "20vw")};
    overflow: ${(props) => (props.navOpen ? "scroll" : "hidden")};
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: 8vw;
  height: auto;
  overflow: visible;
  ${media.tablet} {
    position: absolute;
    width: 14.03vw;
    top: 2.4vw;
    left: 11.99vw;
  }
  ${media.mobile} {
    position: absolute;
    width: 31.2vw;
    top: 5.33vw;
    left: 4.53vw;
  }
`;

const Hamburger = styled.button<{ navOpen: boolean }>`
  background: none;
  display: none;
  position: absolute;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  color: ${(props) => (props.navOpen ? colors.offWhite : "black")};
  transition: color 0.4s;
  svg {
    height: 3.118vw;
    width: 4.077vw;
  }
  ${media.tablet} {
    display: inline-block;

    right: 11.99vw;
    top: 2.861vw;
  }
  ${media.mobile} {
    display: inline-block;

    right: 4.27vw;
    top: 6.267vw;

    svg {
      height: 6.933vw;
      width: 9.067vw;
    }
  }
`;

const PageLink = styled.a<{ activeGrad: boolean }>`
  ${text.desktop.Link};

  color: black;
  text-decoration: none;
  margin-right: 2.778vw;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: 0.4s;
  :after {
    content: "";
    width: 100%;
    height: 1px;
    background: ${colors.drePurple};
    bottom: -0.347vw;
    position: absolute;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.4s;
  }

  ${media.hover} {
    :hover {
      :after {
        transform: scaleX(1);
      }
    }
  }

  ${media.tablet} {
    ${text.tablet.Link};
    font-size: 7.79vw;
    background: ${(props) =>
      props.activeGrad
        ? `linear-gradient(
      rgba(130, 94, 234, 1) 33%,
      rgba(255, 134, 0, 1) 60%
    );`
        : colors.offWhite};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: black;
    -moz-text-fill-color: black;
    text-fill-color: black;
    -webkit-text-stroke: 0.33vw transparent;
    margin-bottom: 7.194vw;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.05em;
  }

  ${media.fullWidth} {
    ${text.fullWidth.Link};
  }
  ${media.mobile} {
    ${text.mobile.Link};
    font-size: 7.79vw;
    background: ${(props) =>
      props.activeGrad
        ? `linear-gradient(
      rgba(130, 94, 234, 1) 33%,
      rgba(255, 134, 0, 1) 60%
    );`
        : colors.offWhite};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: black;
    -moz-text-fill-color: black;
    text-fill-color: black;
    -webkit-text-stroke: 0.54vw transparent;
    margin-bottom: 8vw;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.05em;
  }
`;

const PageLink2 = styled(Link)<{ activeGrad: boolean }>`
  ${text.desktop.Link};

  color: black;
  text-decoration: none;
  margin-right: 2.778vw;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: 0.4s;
  :after {
    content: "";
    width: 100%;
    height: 1px;
    background: ${colors.drePurple};
    bottom: -0.347vw;
    position: absolute;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.4s;
  }

  ${media.hover} {
    :hover {
      :after {
        transform: scaleX(1);
      }
    }
  }

  ${media.tablet} {
    ${text.tablet.Link};
    font-size: 7.79vw;
    background: ${(props) =>
      props.activeGrad
        ? `linear-gradient(
      rgba(130, 94, 234, 1) 33%,
      rgba(255, 134, 0, 1) 60%
    );`
        : colors.offWhite};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: black;
    -moz-text-fill-color: black;
    text-fill-color: black;
    -webkit-text-stroke: 0.33vw transparent;
    margin-bottom: 7.194vw;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.05em;
  }

  ${media.fullWidth} {
    ${text.fullWidth.Link};
  }
  ${media.mobile} {
    ${text.mobile.Link};
    font-size: 7.79vw;
    background: ${(props) =>
      props.activeGrad
        ? `linear-gradient(
      rgba(130, 94, 234, 1) 33%,
      rgba(255, 134, 0, 1) 60%
    );`
        : colors.offWhite};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: black;
    -moz-text-fill-color: black;
    text-fill-color: black;
    -webkit-text-stroke: 0.54vw transparent;
    margin-bottom: 8vw;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.05em;
  }
`;

const Nav = styled.div<{ linkOp: boolean }>`
  display: flex;
  align-items: center;

  ${media.tablet} {
    ${PageLink} {
      opacity: ${(props) => (props.linkOp ? 1 : 0)};
      transition-delay: ${(props) => (props.linkOp ? "0.2s" : "0s")};
      transition: ${(props) => !props.linkOp && "0.2s"};
    }
    flex-direction: column;
    margin-top: 17.51vw;
    align-items: flex-start;
    width: 100%;
    position: relative;
  }
  ${media.mobile} {
    flex-direction: column;
    margin-top: 31.2vw;
    align-items: flex-start;
    width: 100%;
    position: relative;
  }
`;

// const ApplyToSpeak = styled.button`
//   ${buttonAlt};
//   margin-right: 1.19vw;
//   margin-left: 1.389vw;
//   ${media.tablet} {
//     margin-left: 2.4vw;
//     border: 1px solid ${colors.offWhite};
//     margin-right: 0;
//     color: ${colors.offWhite};
//   }

//   ${media.mobile} {
//     border: 1px solid ${colors.offWhite};
//     margin: 0;
//     margin-top: 5.33vw;

//     color: ${colors.offWhite};
//   }
//   ${media.fullWidth} {
//   }
// `;

// const Button = styled.button`
//   ${buttonAlt};
//   margin-right: 1.19vw;
//   margin-left: 1.389vw;
//   ${media.tablet} {
//     margin-left: 2.4vw;
//     border: 1px solid ${colors.offWhite};
//     margin-right: 0;
//     color: ${colors.offWhite};
//   }
//   ${media.mobile} {
//     border: 1px solid ${colors.offWhite};
//     margin: 0;
//     margin-top: 5.33vw;
//     color: ${colors.offWhite};
//   }
// `;

const TabletDiv = styled.div`
  ${media.tablet} {
    display: flex;
    flex-direction: row-reverse;
  }
  ${media.mobile} {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-bottom: 18.13vw;
  }
`;

const CopyRight = styled.div`
  ${media.tablet} {
    margin-bottom: 6vw;
    ${text.tablet.BodyS};
    text-transform: none;
  }
  ${media.mobile} {
    ${text.mobile.BodyS};
    margin-bottom: 20vw;
  }
`;

const Bigeye = styled.div`
  ${media.tablet} {
    ${text.tablet.Overline};
    margin-top: 19.66vw;
  }
  ${media.mobile} {
    ${text.mobile.Overline};
  }
`;

const BigeyeLogo = styled(BigeyeSVG)`
  ${media.tablet} {
    width: 21.48vw;
    height: 6vw;
    margin-top: 1.2vw;
    margin-bottom: 6vw;
    path {
      fill: ${colors.offWhite};
    }
  }
  ${media.mobile} {
    width: 47.73vw;
    height: 13.33vw;
    margin-top: 2.67vw;
    margin-bottom: 8vw;
    path {
      fill: ${colors.offWhite};
    }
  }
`;

const MenuBalls = styled.img`
  ${media.tablet} {
    width: 34.53vw;
    height: 34.89vw;
    right: 0;
    bottom: 0;
    position: absolute;
  }
`;

const LinkHome = styled.a<{ navOpen?: boolean }>`
  text-decoration: none;
  color: ${(props) => (props.navOpen ? colors.offWhite : "black")};
  transition: 0.3s;
  overflow: visible;
`;

export default Header;
